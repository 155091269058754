import { GridRowParams } from '@material-ui/data-grid';
import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';

import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { toast } from 'react-toastify';
import { GiPlug } from 'react-icons/gi';
import { GoPencil } from 'react-icons/go';
import { FiPlusCircle } from 'react-icons/fi';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '~/services/api';
import Search from '~/components/Search';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';

import {
  IFormConfiguracaoEmail,
  ISelect,
  IEmailServidorEntrada,
  ISmtp,
} from './types';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import ModalEmailServidorEntrada from './ModalEmailServidorEntrada';
import ModalSmtp from './ModalSmtp';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Col } from 'react-bootstrap';

const ConfiguracaoDeEmail: React.FC = () => {
  /**
   *  Campos Gerais
   */
  const [initInicializado, setInitInicializado] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [modalEmailServidorEntrada, setModalEmailServidorEntrada] =
    useState(false);
  const [modalSmtp, setModalSmtp] = useState(false);
  const [idEmailServidorEntrada, setIdEmailServidorEntrada] = useState(0);
  const [idSmtp, setIdSmtp] = useState(0);
  const [emailsServidoresEntrada, setEmailsServidoresEntrada] = useState<
    ISelect[]
  >([]);
  const [smtps, setSmtps] = useState<ISelect[]>([]);
  const [emailServidorEntradaIsLoading, setEmailServidorEntradaIsLoading] =
    useState(false);
  const [smtpIsLoading, setSmtpIsLoading] = useState(false);

  const [formConfiguracaoEmail, setFormConfiguracaoEmail] =
    useState<IFormConfiguracaoEmail>({
      cod_email: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_nome: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_servidor_entrada: {
        value: { label: 'Nenhum', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_email_entrada: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_senha_entrada: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_smtp: {
        value: { label: 'Nenhum', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_email: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_senha: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_configuracao_entrada: {
        value: true,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswords = () => {
    setShowPasswords(!showPasswords);
  };

  useEffect(() => {
    getEmailsServidoresEntrada();
    getSmtps();
  }, []);

  useEffect(() => {
    if (!modalSmtp) {
      setIdSmtp(0);
      getSmtps();
    }
    if (!modalEmailServidorEntrada) {
      setIdEmailServidorEntrada(0);
      getEmailsServidoresEntrada();
    }
  }, [modalEmailServidorEntrada, modalSmtp]);

  async function getEmailsServidoresEntrada() {
    try {
      const res = await api.get('/email-servidor-entrada');
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      const regs = data.map((item: IEmailServidorEntrada) => {
        return {
          label: `${item.des_servidor}:${item.num_porta}`,
          value: item.cod_servidor,
        };
      });
      const regsOrder = regs.sort((a: any, b: any) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      regs.unshift({
        value: 0,
        label: 'Nenhum',
      });
      setEmailsServidoresEntrada(regsOrder);
    } catch (e: any) {
      toast.warning(e.message);
    }
  }
  async function getSmtps() {
    try {
      const res = await api.get('/smtp');
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      const regs = data.map((item: ISmtp) => {
        return {
          label: `${item.des_smtp}:${item.num_porta}`,
          value: item.cod_smtp,
        };
      });
      const regsOrder = regs.sort((a: any, b: any) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      regs.unshift({
        value: 0,
        label: 'Nenhum',
      });
      setSmtps(regsOrder);
    } catch (e: any) {
      toast.warning(e.message);
    }
  }

  const onRowClick = async (param: GridRowParams) => {
    try {
      const { cod_email } = param.row;

      const res = await api.get(`/email/${cod_email}`);
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);

      const {
        des_nome,
        cod_servidor_entrada,
        des_email_entrada,
        des_senha_entrada,
        cod_smtp,
        des_email,
        des_senha,
        flg_configuracao_entrada,
      } = data;

      setFormConfiguracaoEmail({
        cod_email: {
          ...formConfiguracaoEmail.cod_email,
          value: cod_email,
          isInvalid: false,
        },
        des_nome: {
          ...formConfiguracaoEmail.des_nome,
          value: des_nome,
          isInvalid: false,
        },
        cod_servidor_entrada: {
          ...formConfiguracaoEmail.cod_servidor_entrada,
          value: { label: 'Nenhum', value: cod_servidor_entrada },
          isInvalid: false,
        },
        des_email_entrada: {
          ...formConfiguracaoEmail.des_email_entrada,
          value: des_email_entrada,
          isInvalid: false,
        },
        des_senha_entrada: {
          ...formConfiguracaoEmail.des_senha_entrada,
          value: des_senha_entrada,
          isInvalid: false,
        },
        cod_smtp: {
          ...formConfiguracaoEmail.cod_smtp,
          value: { label: 'Nenhum', value: cod_smtp },
          isInvalid: false,
        },
        des_email: {
          ...formConfiguracaoEmail.des_email,
          value: des_email,
          isInvalid: false,
        },
        des_senha: {
          ...formConfiguracaoEmail.des_senha,
          value: des_senha,
          isInvalid: false,
        },
        flg_configuracao_entrada: {
          ...formConfiguracaoEmail.flg_configuracao_entrada,
          value: flg_configuracao_entrada,
        },
      });
      setIsUpdate(true);
      setShowSearch(false);
    } catch (e: any) {
      toast.warning(e.message);
    }
  };

  function validaFormConfiguracaoEmail(
    inputs: IFormConfiguracaoEmail,
  ): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formConfiguracaoEmail[
          Object.keys(inputs)[i] as keyof IFormConfiguracaoEmail
        ].isRequired &&
        formConfiguracaoEmail[
          Object.keys(inputs)[i] as keyof IFormConfiguracaoEmail
        ].isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = useCallback(async () => {
    if (!validaEmail(formConfiguracaoEmail.des_email_entrada.value)) {
      setFormConfiguracaoEmail({
        ...formConfiguracaoEmail,
        des_email_entrada: {
          ...formConfiguracaoEmail.des_email_entrada,
          isInvalid: true,
        },
      });
      setInitInicializado(true);
      return toast.warning('[Servidor de Entrada] E-mail inválido.');
    }
    if (!formConfiguracaoEmail.flg_configuracao_entrada.value) {
      if (!validaEmail(formConfiguracaoEmail.des_email.value)) {
        setFormConfiguracaoEmail({
          ...formConfiguracaoEmail,
          des_email: {
            ...formConfiguracaoEmail.des_email,
            isInvalid: true,
          },
        });
        setInitInicializado(true);
        return toast.warning('[SMTP] E-mail inválido.');
      }
    }
    if (validaFormConfiguracaoEmail(formConfiguracaoEmail)) {
      setInitInicializado(true);
      toast.warning('Há informações pendentes.');
      return;
    }
    setInitInicializado(false);
    try {
      const upsert = {
        des_nome: formConfiguracaoEmail.des_nome.value,
        cod_servidor_entrada:
          formConfiguracaoEmail.cod_servidor_entrada.value.value,
        des_email_entrada: formConfiguracaoEmail.des_email_entrada.value,
        des_senha_entrada: formConfiguracaoEmail.des_senha_entrada.value,
        cod_smtp: formConfiguracaoEmail.cod_smtp.value.value,
        des_email: formConfiguracaoEmail.flg_configuracao_entrada.value
          ? ''
          : formConfiguracaoEmail.des_email.value,
        des_senha: formConfiguracaoEmail.flg_configuracao_entrada.value
          ? ''
          : formConfiguracaoEmail.des_senha.value,
        flg_configuracao_entrada:
          formConfiguracaoEmail.flg_configuracao_entrada.value,
      };

      if (!isUpdate) {
        const res = await api.post('/email', upsert);
        const { success, message } = res.data;
        if (!success) throw new Error(message);
        setShowSearch(false);
        onClearForm();
        return toast.success(message);
      }

      const res = await api.put(
        `/email/${formConfiguracaoEmail.cod_email.value}`,
        upsert,
      );
      const { success, message } = res.data;
      if (!success) throw new Error(message);
      setShowSearch(true);
      onClearForm();
      return toast.success(message);
    } catch (e: any) {
      toast.warning(e.message);
    }
  }, [formConfiguracaoEmail]);

  function validaEmail(email: string): boolean {
    const regMail =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regMail.test(email)) {
      toast.warning(
        '[IMAP] E-mail inválido. O email deve conter @ e um domínio válido.',
      );
    }
    return regMail.test(email);
  }

  async function testConfigEmailServidorEntrada() {
    setEmailServidorEntradaIsLoading(true);
    if (!formConfiguracaoEmail.cod_servidor_entrada.value.value) {
      setEmailServidorEntradaIsLoading(false);
      return toast.warning('[Servidor de Entrada] Selecione um servidor.');
    }
    if (formConfiguracaoEmail.des_email_entrada.value === '') {
      setEmailServidorEntradaIsLoading(false);
      return toast.warning(
        '[Servidor de Entrada] Defina o e-mail para efetuar o teste.',
      );
    }

    if (!validaEmail(formConfiguracaoEmail.des_email_entrada.value)) {
      setEmailServidorEntradaIsLoading(false);
      return toast.warning('[Servidor de Entrada] E-mail inválido.');
    }
    try {
      const test = {
        cod_email: formConfiguracaoEmail.cod_email.value || undefined,
        des_email_entrada: formConfiguracaoEmail.des_email_entrada.value,
        des_senha_entrada: formConfiguracaoEmail.des_senha_entrada.value,
        cod_servidor_entrada:
          formConfiguracaoEmail.cod_servidor_entrada.value.value,
      };

      const res = await api.post('/email/test/email-servidor-entrada', test);
      const { message } = res.data;
      toast.success(message);
    } finally {
      setEmailServidorEntradaIsLoading(false);
    }
  }

  async function testConfigSMTP() {
    if (!formConfiguracaoEmail.cod_smtp.value.value) {
      return toast.warning('[SMTP] Selecione um servidor SMTP.');
    }
    if (!formConfiguracaoEmail.flg_configuracao_entrada.value) {
      if (formConfiguracaoEmail.des_email.value === '') {
        return toast.warning('[SMTP] Defina o e-mail para efetuar o teste.');
      }

      if (!validaEmail(formConfiguracaoEmail.des_email.value)) {
        return toast.warning('[SMTP] E-mail inválido.');
      }
    } else {
      if (formConfiguracaoEmail.des_email_entrada.value === '') {
        return toast.warning(
          '[Servidor de Entrada] Defina o e-mail para efetuar o teste.',
        );
      }

      if (!validaEmail(formConfiguracaoEmail.des_email_entrada.value)) {
        return toast.warning('[Servidor de Entrada] E-mail inválido.');
      }
    }
    try {
      setSmtpIsLoading(true);
      const test = {
        cod_email: formConfiguracaoEmail.cod_email.value || undefined,
        des_email: !formConfiguracaoEmail.flg_configuracao_entrada.value
          ? formConfiguracaoEmail.des_email.value
          : formConfiguracaoEmail.des_email_entrada.value,
        des_senha: !formConfiguracaoEmail.flg_configuracao_entrada.value
          ? formConfiguracaoEmail.des_senha.value
          : formConfiguracaoEmail.des_senha_entrada.value,
        cod_smtp: formConfiguracaoEmail.cod_smtp.value.value,
        flg_configuracao_entrada:
          formConfiguracaoEmail.flg_configuracao_entrada.value,
      };
      const res = await api.post('/email/test/smtp', test);
      const { message } = res.data;
      toast.success(message);
    } finally {
      setSmtpIsLoading(false);
    }
  }

  function handleModalEmailServidorEntrada(id?: number) {
    if (id) {
      setIdEmailServidorEntrada(id);
    }
    setModalEmailServidorEntrada(true);
  }

  function handleModalSmtp(id?: number) {
    if (id) {
      setIdSmtp(id);
    }
    setModalSmtp(true);
  }

  async function onDelete() {
    try {
      const res = await api.delete(
        `/email/${formConfiguracaoEmail.cod_email.value}`,
      );
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      setShowSearch(true);
      onClearForm();
      toast.success(message);
      // MySwal.fire({
      //   text: message,
      //   icon: 'success',
      //   showConfirmButton: false,
      // });
    } catch (error: any) {
      toast.warning(error.message);
    }
  }

  const onNewData = async () => {
    onClearForm();
    setShowSearch(false);
    setIsUpdate(false);
  };

  async function onCancel() {
    setIsUpdate(false);
    onClearForm();
    setShowSearch(true);
  }

  function onClearForm() {
    setFormConfiguracaoEmail({
      cod_email: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_nome: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_servidor_entrada: {
        value: { label: 'Nenhum', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_email_entrada: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_senha_entrada: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_smtp: {
        value: { label: 'Nenhum', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_email: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_senha: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_configuracao_entrada: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });
    setInitInicializado(false);
    setIsUpdate(false);
    setShowPassword(false);
    setShowPasswords(false);
  }

  const tst = async (value: any) => {
    const res = await api.get('/email-servidor-entrada');
    const { success, data, message } = res.data;
    if (success) {
      const tsts = data.find((item: ISelect) => {
        return item.des_servidor === value.des_servidor;
      });

      setFormConfiguracaoEmail({
        ...formConfiguracaoEmail,
        cod_servidor_entrada: {
          ...formConfiguracaoEmail.cod_servidor_entrada,
          value: {
            label: `${tsts.des_servidor}:${tsts.num_porta}`,
            value: tsts.cod_servidor,
          },
          isInvalid: false,
        },
      });
    }
  };

  const tex = async (value: any) => {
    const res = await api.get('/smtp');
    const { success, data, message } = res.data;
    if (success) {
      const text = data.find((item: ISelect) => {
        return item.des_smtp === value.des_smtp;
      });
      setFormConfiguracaoEmail({
        ...formConfiguracaoEmail,
        cod_smtp: {
          ...formConfiguracaoEmail.cod_smtp,
          value: { label: '', value: text.cod_smtp },
          isInvalid: false,
        },
      });
    }
  };

  return (
    <>
      <ModalEmailServidorEntrada
        show={modalEmailServidorEntrada}
        handleShow={setModalEmailServidorEntrada}
        handleTest={tst}
        id={idEmailServidorEntrada}
      />
      <Container>
        <ModalSmtp
          show={modalSmtp}
          handleShow={setModalSmtp}
          handleTex={tex}
          id={idSmtp}
        />

        {showSearch && (
          <Search codTela={50} newData={onNewData} onRowClick={onRowClick} />
        )}
        {!showSearch && (
          <FormDefault
            codTela={50}
            title="Configuração de E-mail"
            codigoRegistro={[
              {
                value: formConfiguracaoEmail.cod_email.value,
                des_campo: 'Código',
              },
            ]}
            onSave={async () => {
              await handleSubmit();
            }}
            onCancel={onCancel}
            isUpdate={isUpdate}
            onNew={onNewData}
            onDelete={onDelete}
            onClearFields={onClearForm}
            onReturnSearch={onCancel}
          >
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <InputText
                  label="Nome"
                  placeholder="Digite aqui o nome"
                  maxLength={50}
                  toUpperCase
                  onChange={(value: string) => {
                    setFormConfiguracaoEmail({
                      ...formConfiguracaoEmail,
                      des_nome: {
                        ...formConfiguracaoEmail.des_nome,
                        value,
                        isInvalid: value === '',
                      },
                    });
                  }}
                  value={formConfiguracaoEmail.des_nome.value || ''}
                  isRequired={formConfiguracaoEmail.des_nome.isRequired}
                  setInvalid={formConfiguracaoEmail.des_nome.isInvalid}
                  iniInicializado={initInicializado}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12">
                <Separator labelText="Servidor de Entrada" />
              </div>
            </div>
            <Col sm={12} md={12}>
              <div className="row d-flex align-items-center">
                <div className="col-sm-12 col-md-11">
                  <InputSelect
                    options={emailsServidoresEntrada}
                    label="IMAP/POP3"
                    value={
                      emailsServidoresEntrada.find((item: ISelect) => {
                        return (
                          item.value ===
                          formConfiguracaoEmail.cod_servidor_entrada.value.value
                        );
                      }) ?? {
                        value: undefined,
                        label: 'Selecione ou cadastre um novo IMAP/POP3',
                      }
                    }
                    isRequired={
                      formConfiguracaoEmail.cod_servidor_entrada.isRequired
                    }
                    setInvalid={
                      formConfiguracaoEmail.cod_servidor_entrada.isInvalid
                    }
                    iniInicializado={initInicializado}
                    onChange={(value: any, isInvalid = true) => {
                      setFormConfiguracaoEmail({
                        ...formConfiguracaoEmail,
                        cod_servidor_entrada: {
                          ...formConfiguracaoEmail.cod_servidor_entrada,
                          value,
                          isInvalid: value.value === undefined,
                        },
                      });
                    }}
                  />
                </div>

                <div className="col-sm-12 col-md-1 d-flex align-items-center justify-content-center gap-4 mt-4">
                  <button
                    type="button"
                    className="btn-actions"
                    onClick={() => handleModalEmailServidorEntrada()}
                  >
                    <FiPlusCircle size={24} color="#309063" />
                  </button>
                  {formConfiguracaoEmail.cod_servidor_entrada?.value?.value &&
                  formConfiguracaoEmail.cod_servidor_entrada.value.value !==
                    0 ? (
                    <button
                      type="button"
                      className="btn-actions"
                      onClick={() =>
                        handleModalEmailServidorEntrada(
                          formConfiguracaoEmail.cod_servidor_entrada.value
                            .value,
                        )
                      }
                    >
                      <GoPencil size={24} color="#4c9aff" />
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <InputText
                  label="Email"
                  type="email"
                  placeholder="Digite aqui o email"
                  maxLength={100}
                  onChange={(value: string) => {
                    setFormConfiguracaoEmail({
                      ...formConfiguracaoEmail,
                      des_email_entrada: {
                        ...formConfiguracaoEmail.des_email_entrada,
                        value,
                        isInvalid: value === '',
                      },
                    });
                  }}
                  value={formConfiguracaoEmail.des_email_entrada.value || ''}
                  isRequired={
                    formConfiguracaoEmail.des_email_entrada.isRequired
                  }
                  // setInvalid={formConfiguracaoEmail.des_email_entrada.isInvalid}
                  iniInicializado={initInicializado}
                />
              </div>

              <div className="col-sm-12 col-md-5">
                <Field name="des_senha_entrada" label="Senha">
                  {({ fieldProps }) => (
                    <div className="input-icon">
                      <Textfield
                        {...fieldProps}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={isUpdate ? '' : 'Digite aqui a senha'}
                        maxLength={50}
                        // className={
                        //   formConfiguracaoEmail.des_senha_entrada.isInvalid &&
                        //   formConfiguracaoEmail.des_senha_entrada.isRequired &&
                        //   initInicializado
                        //     ? 'field-error'
                        //     : ''
                        // }
                        value={
                          formConfiguracaoEmail.des_senha_entrada.value || ''
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFormConfiguracaoEmail({
                            ...formConfiguracaoEmail,
                            des_senha_entrada: {
                              ...formConfiguracaoEmail.des_senha_entrada,
                              value: e.target.value,
                              isInvalid: e.target.value === '',
                            },
                          })
                        }
                      />
                      <span
                        className="icon icon-right"
                        onClick={togglePassword}
                      >
                        {!showPassword ? (
                          <FaRegEyeSlash size={16} color="#999999" />
                        ) : (
                          <FaRegEye size={16} color="#999999" />
                        )}
                      </span>
                    </div>
                  )}
                </Field>
              </div>

              <div className="col-sm-12 col-md-2 d-flex justify-content-end">
                <button
                  className="btn btn-primary d-flex align-items-center"
                  type="button"
                  style={{
                    marginTop: '30px',
                    height: '40px',
                  }}
                  onClick={testConfigEmailServidorEntrada}
                >
                  {emailServidorEntradaIsLoading ? (
                    <CircularProgress
                      size={15}
                      className="ml-5"
                      style={{ color: '#ffffff' }}
                    />
                  ) : (
                    <div>
                      <GiPlug
                        size={18}
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      <span className="ml-5">Testar</span>
                    </div>
                  )}
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12">
                <Separator labelText="Servidor de Saída" />
              </div>
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-sm-12 col-md-3">
                <ToggleDefault
                  labelText="Usar Configurações de Entrada"
                  setChecked={
                    formConfiguracaoEmail.flg_configuracao_entrada.value
                  }
                  onSwitch={() =>
                    setFormConfiguracaoEmail({
                      ...formConfiguracaoEmail,
                      flg_configuracao_entrada: {
                        ...formConfiguracaoEmail.flg_configuracao_entrada,
                        value:
                          !formConfiguracaoEmail.flg_configuracao_entrada.value,
                      },
                    })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-11">
                <InputSelect
                  options={smtps}
                  label="SMTP"
                  value={
                    smtps.find((item: ISelect) => {
                      return (
                        item.value ===
                        formConfiguracaoEmail.cod_smtp.value.value
                      );
                    }) ?? {
                      value: undefined,
                      label: 'Selecione ou cadastre um novo SMTP',
                    }
                  }
                  isRequired={formConfiguracaoEmail.cod_smtp.isRequired}
                  setInvalid={formConfiguracaoEmail.cod_smtp.isInvalid}
                  iniInicializado={initInicializado}
                  onChange={(value: any, isInvalid = true) => {
                    setFormConfiguracaoEmail({
                      ...formConfiguracaoEmail,
                      cod_smtp: {
                        ...formConfiguracaoEmail.cod_smtp,
                        value,
                        isInvalid: value.value === undefined,
                      },
                    });
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-1 d-flex align-items-center justify-content-center gap-4 mt-4">
                <button
                  type="button"
                  className="btn-actions"
                  onClick={() => handleModalSmtp()}
                >
                  <FiPlusCircle size={24} color="#309063" />
                </button>
                {formConfiguracaoEmail?.cod_smtp?.value?.value &&
                formConfiguracaoEmail.cod_smtp.value.value !== 0 ? (
                  <button
                    type="button"
                    className="btn-actions"
                    onClick={() =>
                      handleModalSmtp(
                        formConfiguracaoEmail.cod_smtp.value.value,
                      )
                    }
                  >
                    <GoPencil size={24} color="#4c9aff" />
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <InputText
                  label="Email"
                  placeholder="Digite aqui o email"
                  type="email"
                  maxLength={100}
                  isDisabled={
                    formConfiguracaoEmail.flg_configuracao_entrada.value
                  }
                  onChange={(value: string) => {
                    setFormConfiguracaoEmail({
                      ...formConfiguracaoEmail,
                      des_email: {
                        ...formConfiguracaoEmail.des_email,
                        value,
                        isInvalid: value === '',
                      },
                    });
                  }}
                  value={
                    formConfiguracaoEmail.flg_configuracao_entrada.value
                      ? formConfiguracaoEmail.des_email_entrada.value
                      : formConfiguracaoEmail.des_email.value || ''
                  }
                  isRequired={
                    formConfiguracaoEmail.cod_smtp.isRequired &&
                    !formConfiguracaoEmail.flg_configuracao_entrada.value
                  }
                  setInvalid={
                    formConfiguracaoEmail.cod_smtp.isInvalid &&
                    !formConfiguracaoEmail.flg_configuracao_entrada.value
                  }
                  iniInicializado={
                    initInicializado &&
                    !formConfiguracaoEmail.flg_configuracao_entrada.value
                  }
                />
              </div>

              <div className="col-sm-12 col-md-5">
                <Field name="des_senha" label="Senha">
                  {({ fieldProps }) => (
                    <div className="input-icon">
                      <Textfield
                        {...fieldProps}
                        type={showPasswords ? 'text' : 'password'}
                        placeholder={isUpdate ? '' : 'Digite aqui a senha'}
                        maxLength={50}
                        className={
                          formConfiguracaoEmail.des_senha_entrada.isInvalid &&
                          formConfiguracaoEmail.des_senha_entrada.isRequired &&
                          initInicializado
                            ? 'field-error'
                            : ''
                        }
                        isDisabled={
                          formConfiguracaoEmail.flg_configuracao_entrada.value
                        }
                        value={
                          formConfiguracaoEmail.flg_configuracao_entrada.value
                            ? formConfiguracaoEmail.des_senha_entrada.value ||
                              ''
                            : formConfiguracaoEmail.des_senha.value || ''
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFormConfiguracaoEmail({
                            ...formConfiguracaoEmail,
                            des_senha: {
                              ...formConfiguracaoEmail.des_senha,
                              value: e.target.value,
                              isInvalid: e.target.value === '',
                            },
                          })
                        }
                      />
                      <span
                        className="icon icon-right"
                        onClick={togglePasswords}
                      >
                        {!showPasswords ? (
                          <FaRegEyeSlash size={16} color="#999999" />
                        ) : (
                          <FaRegEye size={16} color="#999999" />
                        )}
                      </span>
                    </div>
                  )}
                </Field>
              </div>

              <div className="col-sm-12 col-md-2 d-flex justify-content-end">
                <button
                  className="btn btn-primary d-flex align-items-center"
                  type="button"
                  style={{
                    marginTop: '30px',
                    height: '40px',
                  }}
                  onClick={testConfigSMTP}
                >
                  {smtpIsLoading ? (
                    <CircularProgress
                      size={15}
                      className="ml-5"
                      style={{ color: '#ffffff' }}
                    />
                  ) : (
                    <>
                      <GiPlug
                        size={18}
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      <span className="ml-5">Testar</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </FormDefault>
        )}
      </Container>
    </>
  );
};

export default ConfiguracaoDeEmail;
