/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import api from '~/services/api';
import InputText from '~/components/Inputs/InputText';

import { IFormEmailServidorEntrada } from './types';
import ToggleDefault from '~/components/ToggleDefault';
import InputNumber from '~/components/Inputs/InputNumber';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { Modal } from 'react-bootstrap';

interface FormProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleTest: (value: any) => void;
  id: number;
}

const ModalEmailServidorEntrada: React.FC<FormProps> = ({
  show,
  handleShow,
  handleTest,
  id,
}) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [initInicializado, setInitInicializado] = useState(false);

  useEffect(() => {
    if (id !== 0) {
      getEmailServidor();
      setIsUpdate(true);
    } else {
      clearForm();
      setIsUpdate(false);
    }
  }, [show]);

  async function getEmailServidor() {
    const res = await api.get(`/email-servidor-entrada/${id}`);
    const { data } = res.data;
    const { des_servidor, num_porta, flg_conexao_ssl } = data;

    setFormEmailServidorEntrada({
      des_servidor: {
        ...formEmailServidorEntrada.des_servidor,
        value: des_servidor,
        isInvalid: false,
      },
      num_porta: {
        ...formEmailServidorEntrada.num_porta,
        value: num_porta,
        isInvalid: false,
      },
      flg_conexao_ssl: {
        ...formEmailServidorEntrada.flg_conexao_ssl,
        value: flg_conexao_ssl,
      },
    });
  }

  const [formEmailServidorEntrada, setFormEmailServidorEntrada] =
    useState<IFormEmailServidorEntrada>({
      des_servidor: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      num_porta: {
        value: 0,
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      flg_conexao_ssl: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
    });

  function validaformEmailServidorEntrada(
    inputs: IFormEmailServidorEntrada,
  ): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formEmailServidorEntrada[
          Object.keys(inputs)[i] as keyof IFormEmailServidorEntrada
        ].isRequired &&
        formEmailServidorEntrada[
          Object.keys(inputs)[i] as keyof IFormEmailServidorEntrada
        ].isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  async function onSubmit() {
    if (validaformEmailServidorEntrada(formEmailServidorEntrada)) {
      setInitInicializado(true);
      toast.warning('Há informações pendentes.');
      return;
    }

    if (Number.isNaN(formEmailServidorEntrada.num_porta.value)) {
      return toast.warning('Número da porta deve ser diferente de zero');
    }
    setInitInicializado(false);
    try {
      const upsert = {
        des_servidor: formEmailServidorEntrada.des_servidor.value,
        num_porta: formEmailServidorEntrada.num_porta.value,
        flg_conexao_ssl: formEmailServidorEntrada.flg_conexao_ssl.value,
      };
      if (!isUpdate) {
        const res = await api.post('/email-servidor-entrada', upsert);
        const { success, data, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        handleTest(upsert);
        handleShow(false);
        return toast.success(message);
      }
      const res = await api.put(`/email-servidor-entrada/${id}`, upsert);
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      handleShow(false);
      return toast.success(message);
    } catch (e: any) {
      toast.warning(e.message);
    }
  }

  function onCancel() {
    clearForm();
    handleShow(false);
  }

  function clearForm() {
    setFormEmailServidorEntrada({
      des_servidor: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      num_porta: {
        value: 0,
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      flg_conexao_ssl: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
    });
  }

  return (
    <>
      {show && (
        <Modal
          show={show}
          onHide={() => handleShow(false)}
          className="modal-fade absolute inset-0 overflow-auto bg-gray-50"
          id="modalEmailServidorEntrada"
          centered
          size="lg"
        >
          <Modal.Header>
            <Modal.Title id="modalEmailServidorEntrada">
              {!isUpdate ? 'Cadastrar' : 'Editar'} Servidor IMAP ou POP3
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 col-md-10">
                <InputText
                  label="IMAP/POP3"
                  placeholder=""
                  maxLength={100}
                  onChange={(value: string) => {
                    setFormEmailServidorEntrada({
                      ...formEmailServidorEntrada,
                      des_servidor: {
                        ...formEmailServidorEntrada.des_servidor,
                        value,
                        isInvalid: value === '',
                      },
                    });
                  }}
                  value={formEmailServidorEntrada.des_servidor.value || ''}
                  isRequired={formEmailServidorEntrada.des_servidor.isRequired}
                  setInvalid={formEmailServidorEntrada.des_servidor.isInvalid}
                  iniInicializado={initInicializado}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputNumber
                  label="Porta"
                  placeholder=""
                  maxLength={100}
                  min={1}
                  max={65536}
                  onChange={(value: number) => {
                    if (value >= 1 && value <= 65536) {
                      setFormEmailServidorEntrada({
                        ...formEmailServidorEntrada,
                        num_porta: {
                          ...formEmailServidorEntrada.num_porta,
                          value,
                          isInvalid: value === 0,
                        },
                      });
                    }
                  }}
                  value={formEmailServidorEntrada.num_porta.value || 0}
                  isRequired={formEmailServidorEntrada.num_porta.isRequired}
                  setInvalid={formEmailServidorEntrada.num_porta.isInvalid}
                  iniInicializado={initInicializado}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="mt-3">
            <ToggleDefault
              labelText="Utilizar Conexão Segura SSL/TLS"
              setChecked={formEmailServidorEntrada.flg_conexao_ssl.value}
              onSwitch={() =>
                setFormEmailServidorEntrada({
                  ...formEmailServidorEntrada,
                  flg_conexao_ssl: {
                    ...formEmailServidorEntrada.flg_conexao_ssl,
                    value: !formEmailServidorEntrada.flg_conexao_ssl.value,
                  },
                })
              }
            />
            <div className="d-flex col justify-content-end">
              <ConfirmButton onClick={() => onSubmit()}>
                Confirmar
              </ConfirmButton>
              <CancelButton onClick={() => onCancel()}>Cancelar</CancelButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalEmailServidorEntrada;
